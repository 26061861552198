import { useEffect, useState } from 'react';
import { useMarginsQuery } from 'src/api/queries';
import { useFilterContext } from 'src/contexts/FilterContext';
import { INIT_SIMPLIFIED_DATA_STATE, SimplifiedDataState } from 'src/types';
import { aggregateSimpleData } from 'src/utils/aggregate';
import { toApiDates } from 'src/utils/date';

export const useFetchMargins = (enabled: boolean) => {
  const { currentModelVersion, timeRange } = useFilterContext();

  const [margins, setMargins] = useState<SimplifiedDataState>(
    INIT_SIMPLIFIED_DATA_STATE,
  );

  const {
    data: apiData,
    isLoading,
    isError,
  } = useMarginsQuery(
    {
      customer: currentModelVersion?.customer ?? 'missing_customer',
      model_build: currentModelVersion?.buildID ?? 'missing_buildid',
      ...toApiDates(timeRange),
    },
    enabled,
  );

  useEffect(() => {
    if (!enabled) {
      setMargins(INIT_SIMPLIFIED_DATA_STATE);
      return;
    }

    const data = apiData ?? [];

    const marginsData = aggregateSimpleData('margin', data, isLoading, isError);

    setMargins(marginsData);
  }, [apiData, isLoading, isError, enabled]);

  return { margins };
};
