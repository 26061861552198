import { SosLocation } from 'src/features/SOS/types';

export const sortByName = <T extends { name: string }>(arr: T[]) => {
  return [...arr].sort((a, b) =>
    a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1,
  );
};

export const sortByAlias = <T extends { alias: string }>(arr: T[]) => {
  return [...arr].sort((a, b) =>
    a.alias.toLowerCase() < b.alias.toLowerCase() ? -1 : 1,
  );
};

export const sortByLabel = <T extends { label: string }>(arr: T[]) => {
  return [...arr].sort((a, b) =>
    a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1,
  );
};

export const sortByMetric = <T extends { metric: string }>(arr: T[]) => {
  return [...arr].sort((a, b) =>
    a.metric.toLowerCase() < b.metric.toLowerCase() ? -1 : 1,
  );
};

export const sortStrings = (arr: Array<string>) => {
  return [...arr].sort((a, b) => (a.toLowerCase() < b.toLowerCase() ? -1 : 1));
};

export const sortByLocationName = <A extends SosLocation>(arr: Array<A>) => {
  return [...arr].sort((a, b) =>
    a.locationName.toLowerCase() < b.locationName.toLowerCase() ? -1 : 1,
  );
};

export const sortDataWithLocation = <A extends { location: SosLocation }>(
  arr: Array<A>,
): Array<A> => {
  return [...arr].sort((a, b) =>
    a.location.locationName.toLowerCase() < b.location.locationName ? -1 : 1,
  );
};
