import { createContext, useContext } from 'react';

export type CustomersList = {
  customers: string[];
  isLoading: boolean;
  isError: boolean;
};

// This is used to enable/disable views in the app based
// on the subscription level of the customer.
// The actual authorization of access to data/actions is
// enforced by the API (and must remain this way)
// These are purely for the user experience in the app.
export type SubscriptionLevels = {
  mm: boolean;
  da: boolean;
  mo: boolean;
  lt: boolean;
  sos: boolean;
  isLoading: boolean;
  isError: boolean;
};

export interface AppContextValue {
  currentCustomer: string | null;
  setCurrentCustomer: (customer: string) => void;
  customersList: CustomersList;
  subscriptionLevels: SubscriptionLevels;
  isSuperuser: boolean;
}

export const AppContext = createContext<AppContextValue | null>(null);
AppContext.displayName = 'AppContext';

export function useAppContext() {
  const value = useContext(AppContext);
  if (!value) {
    throw new Error('useAppContext has to be used under AppContextProvider');
  }
  return value;
}
