import { useEffect, useState } from 'react';
import {
  ApiModelVersionDataPoint,
  useModelVersionsQuery,
} from 'src/api/queries';
import { AppServices } from 'src/app/routes/utils';
import { sortByAlias } from 'src/utils/sort';
import { useAppContext } from '../AppContext';

export type ModelVersion = ApiModelVersionDataPoint & { customer: string };

export type ModelVersionsState = {
  modelVersions: Array<ModelVersion>;
  isLoading: boolean;
  isError: boolean;
};

const INIT_MODEL_VERSIONS_STATE: Readonly<ModelVersionsState> = {
  modelVersions: [],
  isError: false,
  isLoading: false,
};

export const useModelVersions = (
  currentAppService: AppServices | null,
  setCurrentModelVersion: (modelVersion: ModelVersion | null) => void,
  updateTimeRangeFromModel: (modelVersion: ModelVersion) => void,
  timeRangeLocked: boolean,
) => {
  const { currentCustomer, subscriptionLevels } = useAppContext();

  const [modelVersionsState, setAllModelVersionsState] =
    useState<ModelVersionsState>(INIT_MODEL_VERSIONS_STATE);

  const enabled =
    !!currentCustomer &&
    !!currentAppService &&
    !!subscriptionLevels[currentAppService];

  const {
    data: mvData,
    isLoading: mvLoading,
    isError: mvError,
  } = useModelVersionsQuery(
    {
      customer: currentCustomer ?? 'missing_customer',
      model_type: currentAppService ?? ('missing_model_Type' as AppServices),
    },
    enabled,
  );

  // After data has been fetched, update the context for each:
  useEffect(() => {
    if (!enabled) {
      setAllModelVersionsState(INIT_MODEL_VERSIONS_STATE);
      setCurrentModelVersion(null);
      return;
    }

    const data = mvData ?? [];

    const modelVersions = data.map((d) => ({
      ...d,
      customer: currentCustomer,
    }));

    setAllModelVersionsState({
      modelVersions: sortByAlias(modelVersions),
      isLoading: mvLoading,
      isError: mvError,
    });

    if (modelVersions.length > 0) {
      const newModelVersion = modelVersions[0];
      setCurrentModelVersion(newModelVersion);
      if (!timeRangeLocked) {
        updateTimeRangeFromModel(newModelVersion);
      }
    } else {
      setCurrentModelVersion(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    enabled,
    mvData,
    mvLoading,
    mvError,
    currentCustomer,
    setCurrentModelVersion,
    updateTimeRangeFromModel,
  ]);

  return {
    modelVersionsState,
    setCurrentModelVersion,
  };
};
