import { useEffect, useState } from 'react';
import {
  ApiOrganicSearchDataPoint,
  useOrganicSearchQuery,
} from 'src/api/queries';
import { useFilterContext } from 'src/contexts/FilterContext';
import { toApiDates } from 'src/utils/date';
import { filterOrganicSearchData } from '../../utils/filters';
import { getUniqueKeywordClusters } from '../../utils/organicSearchAggregator';

export type OrganicSearchData = {
  data: ApiOrganicSearchDataPoint[];
  isLoading: boolean;
  isError: boolean;
};

const INIT_ORGANIC_SEARCH_DATA: Readonly<OrganicSearchData> = {
  data: [],
  isLoading: false,
  isError: false,
};

// Supposed to only be called from context
export const useFetchOrganicSearch = (enabled: boolean) => {
  const { currentModelVersion, timeRange, benchmarkTimerange } =
    useFilterContext();

  const [organicSearch, setOrganicSearch] = useState<OrganicSearchData>(
    INIT_ORGANIC_SEARCH_DATA,
  );

  const [benchmarkOrganicSearch, setBenchmarkOrganicSearch] =
    useState<OrganicSearchData>(INIT_ORGANIC_SEARCH_DATA);

  const [keywordClusterFilters, setKeywordClustersFilters] = useState<
    Record<string, boolean>
  >({});

  const organicSearchQueryBase = {
    customer: currentModelVersion?.customer ?? 'missing_customer',
    model_build: currentModelVersion?.buildID ?? 'missing_buildid',
    model_id: currentModelVersion?.modelID ?? 'model_id_missing',
  };

  const {
    data: apiData,
    isLoading,
    isError,
  } = useOrganicSearchQuery(
    {
      ...organicSearchQueryBase,
      ...toApiDates(timeRange),
    },
    enabled,
  );

  // Benchmark timerange organic search data
  const benchmarkQueryEnabled =
    enabled && benchmarkTimerange.enabled && !benchmarkTimerange.error;
  const {
    data: benchmarkData,
    isLoading: isBenchmarkLoading,
    isError: isBenchmarkError,
  } = useOrganicSearchQuery(
    {
      ...organicSearchQueryBase,
      ...toApiDates(benchmarkTimerange),
    },
    benchmarkQueryEnabled,
  );

  useEffect(() => {
    if (!enabled) {
      setKeywordClustersFilters({});
      setOrganicSearch(INIT_ORGANIC_SEARCH_DATA);
      return;
    }
    const data = apiData ?? [];
    setKeywordClustersFilters(getUniqueKeywordClusters(data));
    setOrganicSearch({ data, isError, isLoading });
  }, [isLoading, isError, apiData, enabled]);

  useEffect(() => {
    if (!benchmarkQueryEnabled) {
      setBenchmarkOrganicSearch(INIT_ORGANIC_SEARCH_DATA);
      return;
    }
    const data = benchmarkData ?? [];
    setBenchmarkOrganicSearch({
      data,
      isError: isBenchmarkError,
      isLoading: isBenchmarkLoading,
    });
  }, [
    isBenchmarkLoading,
    isBenchmarkError,
    benchmarkData,
    benchmarkQueryEnabled,
  ]);

  const toggleKeywordCluster = (clusterName: string) => {
    const newFilters = { ...keywordClusterFilters };
    if (newFilters[clusterName] !== undefined) {
      newFilters[clusterName] = !newFilters[clusterName];
    }
    setKeywordClustersFilters(newFilters);
    setOrganicSearch({
      ...organicSearch,
      data: filterOrganicSearchData(apiData ?? [], newFilters),
    });
  };

  const toggleAllKeywordClusters = () => {
    const isAllSelected = Object.values(keywordClusterFilters).reduce(
      (prev, curr) => prev && curr,
      true,
    );

    const newFilters = { ...keywordClusterFilters };

    Object.keys(keywordClusterFilters).forEach((clusterName) => {
      newFilters[clusterName] = !isAllSelected;
    });
    setKeywordClustersFilters(newFilters);
    setOrganicSearch({
      ...organicSearch,
      data: filterOrganicSearchData(apiData ?? [], newFilters),
    });
  };

  return {
    organicSearch,
    benchmarkOrganicSearch,
    keywordClusterFilters,
    toggleKeywordCluster,
    toggleAllKeywordClusters,
  };
};
