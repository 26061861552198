import { useMemo } from 'react';
import { DataBox, DataBoxBaseProps } from 'src/components/molecules/DataBox';

import { useAppContext } from 'src/contexts/AppContext';
import { useSOSDataContext } from 'src/features/SOS/contexts/SOSDataContext';
import { splitSearchVolmeToBrandAndOthers } from 'src/features/SOS/utils/splitSearchVolumeToBrandAndOthers';
import { dayjs } from 'src/libs/dayjs';
import { cleanCustomerName } from 'src/utils/cleanCustomerName';
import { getMonthStartEpoch, sortEpoch } from 'src/utils/date';
import {
  BrandVsOthersSearchVolumeChart,
  BrandVsOthersSearchVolumeDataPoint,
} from './BrandVsOthersSearchVolumeChart';
import { getLegendProps } from './constants';

export const BrandVsOthersSearchVolumeChartBox: React.FC<DataBoxBaseProps> = (
  props,
) => {
  const { currentCustomer } = useAppContext();
  const { shareOfSearchCountryMonthlyState } = useSOSDataContext();

  const data: Array<BrandVsOthersSearchVolumeDataPoint> = useMemo(() => {
    if (!currentCustomer) {
      return [];
    }
    const { brandData, otherData } = splitSearchVolmeToBrandAndOthers(
      currentCustomer,
      shareOfSearchCountryMonthlyState.data,
    );

    const dataPoints: Array<BrandVsOthersSearchVolumeDataPoint> =
      Object.entries(brandData).map(([utcEpochString, searchVolume]) => {
        const utcEpoch = Number(utcEpochString);
        const brandSearchVolume = searchVolume;
        const otherSearchVolume = otherData[utcEpoch];

        const previousYearEpoch = getMonthStartEpoch(
          dayjs(utcEpoch).subtract(1, 'year').valueOf(),
        );

        const previousYearBranchVolume = brandData[previousYearEpoch] ?? 0;
        const previousYearChange =
          previousYearBranchVolume > 0
            ? (100 * (brandSearchVolume - previousYearBranchVolume)) /
              previousYearBranchVolume
            : null;
        return {
          brandSearchVolume,
          otherSearchVolume,
          previousYearChange,
          utcEpoch,
        };
      });

    return dataPoints.sort(sortEpoch);
  }, [shareOfSearchCountryMonthlyState, currentCustomer]);

  const legendProps = useMemo(() => {
    return getLegendProps(currentCustomer);
  }, [currentCustomer]);

  return (
    <DataBox
      {...props}
      legendProps={legendProps}
      hasData={shareOfSearchCountryMonthlyState.data.length > 0}
      isError={shareOfSearchCountryMonthlyState.isError}
      isLoading={shareOfSearchCountryMonthlyState.isLoading}>
      <BrandVsOthersSearchVolumeChart
        data={data}
        brandName={cleanCustomerName(currentCustomer) ?? ''}
      />
    </DataBox>
  );
};
