import { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { useLongTermEffectsQuery } from 'src/api/queries';
import { useAppContext } from 'src/contexts/AppContext';
import { useFilterContext } from 'src/contexts/FilterContext';
import { dayjs } from 'src/libs/dayjs';
import { TimePeriod } from 'src/types';
import { LTDataContext } from '.';
import { useEffects } from '../../hooks/useEffects';
import { useInvestments } from '../../hooks/useInvestments';
import { useRoi } from '../../hooks/useRoi';
import { LongTermEffectsState } from '../../types';
import { filterLongTermEffectsData } from '../../utils/filters';

const INIT_LONG_TERM_EFFECTS_STATE: Readonly<LongTermEffectsState> = {
  isError: false,
  isLoading: false,
  data: [],
};

export function LTDataContextProvider(props: PropsWithChildren) {
  const { subscriptionLevels } = useAppContext();
  const { currentModelVersion, media, updateTimeRange, timeRange } =
    useFilterContext();

  const [longTermEffects, setLongTermEffects] = useState<LongTermEffectsState>(
    INIT_LONG_TERM_EFFECTS_STATE,
  );

  // Make sure we only run queries if customers have the correct subscriptions enabled
  // and a model version is selected (so that there are no errors & data is available)
  const enabled =
    !subscriptionLevels.isLoading &&
    !subscriptionLevels.isError &&
    !!subscriptionLevels.lt &&
    !!currentModelVersion;

  const { data, isLoading, isError } = useLongTermEffectsQuery(
    {
      customer: currentModelVersion?.customer ?? '',
      model_build: currentModelVersion?.buildID ?? '',
      model_id: currentModelVersion?.modelID ?? '',
    },
    enabled,
  );

  useEffect(() => {
    if (enabled) {
      updateTimeRange(
        TimePeriod.CUSTOM,
        dayjs(currentModelVersion.dataStartDate),
        dayjs(currentModelVersion.dataEndDate),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled, currentModelVersion]);

  useEffect(() => {
    if (!enabled) {
      setLongTermEffects(INIT_LONG_TERM_EFFECTS_STATE);
      return;
    }

    const filteredData = filterLongTermEffectsData(
      data ?? [],
      media,
      timeRange,
    );

    setLongTermEffects({
      isError,
      isLoading,
      data: filteredData,
    });
  }, [enabled, data, isError, isLoading, media, timeRange]);

  const effects = useEffects(longTermEffects.data, enabled);
  const investments = useInvestments(longTermEffects.data, enabled);
  const roi = useRoi(longTermEffects.data, enabled);

  const value = useMemo(() => {
    return {
      effects,
      investments,
      roi,
      isError: longTermEffects.isError,
      isLoading: longTermEffects.isLoading,
    };
  }, [
    effects,
    investments,
    roi,
    longTermEffects.isError,
    longTermEffects.isLoading,
  ]);

  return (
    <LTDataContext.Provider value={value}>
      {props.children}
    </LTDataContext.Provider>
  );
}
