import { PropsWithChildren, useMemo } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { useFilterContext } from 'src/contexts/FilterContext';
import { useFetchProductionCosts } from 'src/features/MM/hooks/fetch/useFetchProductionCosts';
import { useABCResponseCurveFetch } from 'src/hooks/fetch/useABCResponseCurveFetch';
import { useFetchSales } from 'src/hooks/fetch/useFetchSales';
import { MMDataContext } from '.';
import { useFetchInvestments } from '../../hooks/fetch/useFetchInvestments';

import { useFetchMargins } from '../../hooks/fetch/useFetchMargins';
import { useFetchPredictedSales } from '../../hooks/fetch/useFetchPredictedSales';

import { useFetchTotalDecomps } from '../../hooks/fetch/useFetchTotalDecomps';

import { useFetchDecomps } from '../../hooks/fetch/useFetchDecomps';
import { useCostPerUnit } from '../../hooks/useCostPerUnit';
import { useMediaEffect } from '../../hooks/useMediaEffect';
import { useRoi } from '../../hooks/useRoi';
import { useRomi } from '../../hooks/useRomi';

export function MMDataContextProvider(props: PropsWithChildren) {
  // Get the customer ID for queries
  const { subscriptionLevels } = useAppContext();
  // Get the current filters for queries & filtering data
  const { currentModelVersion } = useFilterContext();

  // Make sure we only run queries if customers have the correct subscriptions enabled
  // and a model version is selected (so that there are no errors & data is available)
  const enabled =
    !subscriptionLevels.isLoading &&
    !subscriptionLevels.isError &&
    !!subscriptionLevels.mm &&
    !!currentModelVersion;

  const { productionCosts } = useFetchProductionCosts(enabled);
  const { sales } = useFetchSales(enabled);
  const { predictedSales } = useFetchPredictedSales(enabled);
  const { totalDecomps } = useFetchTotalDecomps(enabled);
  const { responseCurve } = useABCResponseCurveFetch(enabled);
  const { margins } = useFetchMargins(enabled);

  // New implementations
  const { investments } = useFetchInvestments(enabled);
  const { decomps } = useFetchDecomps(enabled);
  const { roi } = useRoi(enabled, investments, decomps);

  const { cpu } = useCostPerUnit(enabled, investments, decomps);

  const { romi, romiEnabled } = useRomi(
    enabled,
    investments,
    decomps,
    sales,
    margins,
    productionCosts,
  );
  const { mediaEffect } = useMediaEffect(enabled, decomps, predictedSales);

  const value = useMemo(() => {
    return {
      sales,
      predictedSales,
      totalDecomps,
      responseCurve,
      margins,
      roi,
      romi,
      romiEnabled,
      investments,
      decomps,
      cpu,
      mediaEffect,
    };
  }, [
    sales,
    predictedSales,
    totalDecomps,
    responseCurve,
    margins,
    roi,
    romi,
    romiEnabled,
    investments,
    decomps,
    cpu,
    mediaEffect,
  ]);

  return (
    <MMDataContext.Provider value={value}>
      {props.children}
    </MMDataContext.Provider>
  );
}
