import { useEffect, useState } from 'react';
import { usePredictedSalesQuery } from 'src/api/queries';
import { useFilterContext } from 'src/contexts/FilterContext';
import { INIT_SIMPLIFIED_DATA_STATE, SimplifiedDataState } from 'src/types';
import { aggregateSimpleData } from 'src/utils/aggregate';
import { toApiDates } from 'src/utils/date';

export const useFetchPredictedSales = (enabled: boolean) => {
  const { currentModelVersion, timeRange } = useFilterContext();

  const [predictedSales, setPredictedSales] = useState<SimplifiedDataState>(
    INIT_SIMPLIFIED_DATA_STATE,
  );

  const {
    data: apiData,
    isLoading,
    isError,
  } = usePredictedSalesQuery(
    {
      customer: currentModelVersion?.customer ?? 'missing_customer',
      model_build: currentModelVersion?.buildID ?? 'missing_buildid',
      model_id: currentModelVersion?.modelID ?? 'missing_modelid',
      ...toApiDates(timeRange),
    },
    enabled,
  );

  useEffect(() => {
    if (!enabled) {
      setPredictedSales(INIT_SIMPLIFIED_DATA_STATE);
      return;
    }

    const data = apiData ?? [];

    const predictedSalesData = aggregateSimpleData(
      'sales',
      data,
      isLoading,
      isError,
    );
    setPredictedSales(predictedSalesData);
  }, [apiData, isLoading, isError, enabled]);

  return { predictedSales };
};
