import React, { useMemo } from 'react';
import { useAppContext } from '../../../../../contexts/AppContext';
import { useLTDataContext } from '../../../contexts/LTDataContext';
import { calculateRoi } from '../../../../../utils/calculateRoi';
import {
  KeyMetricChart,
  KeyMetricChartData,
} from '../../../../../components/organisms/KeyMetricChart';

export const ROI: React.FC = () => {
  const { roi, isError, isLoading } = useLTDataContext();

  const { currentCustomer } = useAppContext();

  const keyMetricData: KeyMetricChartData = useMemo(() => {
    const effectSum = roi.monthly.reduce((prev, curr) => {
      return prev + curr.totalEffect;
    }, 0);

    const investmentsSum = roi.monthly.reduce((prev, curr) => {
      return prev + curr.investments;
    }, 0);

    return {
      isLoading,
      isError,
      name: 'ROI',
      isEuro: false,
      summaryValue: calculateRoi({
        mediaIncrementalSales: effectSum,
        investment: investmentsSum,
        customer: currentCustomer,
      }),
      data: roi.monthly.map((month) => ({
        utcEpoch: month.utcEpoch,
        value: month.totalEffectROI,
      })),
    };
  }, [roi, isError, isLoading, currentCustomer]);

  return <KeyMetricChart keyMetricData={keyMetricData} />;
};
