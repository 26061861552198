import { useEffect, useState } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ApiShareOfSearchByLocationDataPoint,
  useShareOfSearchByLocationQuery,
} from '../api/queries';
import { mapAndFilterSosByLocationData } from '../utils/mapAndFilterSosByLocationData';

export type SelectableKeyword = {
  keyword: string;
  selected: boolean;
};

export type ShareOfSearchMonthlyState = {
  data: Array<ApiShareOfSearchByLocationDataPoint>;
  selectableKeywords: SelectableKeyword[];
  isLoading: boolean;
  isError: boolean;
};

const INIT_SHARE_OF_SEARCH_STATE: ShareOfSearchMonthlyState = {
  data: [],
  selectableKeywords: [],
  isError: false,
  isLoading: false,
};

export const sortKeywordData = <T extends { keyword: string }>(a: T, b: T) => {
  return a.keyword.localeCompare(b.keyword);
};

const getInitialSelectableKeywords = (
  data: Array<ApiShareOfSearchByLocationDataPoint>,
): Array<SelectableKeyword> => {
  const keywordSet = new Set(
    data.flatMap((d) => {
      return d.keywords.map(({ keyword }) => keyword);
    }),
  );
  return Array.from(keywordSet)
    .map((keyword) => ({ keyword, selected: true }))
    .sort(sortKeywordData);
};

export const useShareOfSearchCountryMonthly = (
  enabled: boolean,
  countryId: number,
) => {
  const { currentCustomer } = useAppContext();

  const [
    shareOfSearchCountryMonthlyState,
    setShareOfSearchCountryMonthlyState,
  ] = useState<ShareOfSearchMonthlyState>(INIT_SHARE_OF_SEARCH_STATE);

  const {
    data: apiData,
    isError,
    isLoading,
  } = useShareOfSearchByLocationQuery(
    {
      customer: currentCustomer ?? 'no_customer',
      location_id: countryId,
    },
    enabled,
  );

  useEffect(() => {
    if (!enabled) {
      setShareOfSearchCountryMonthlyState(INIT_SHARE_OF_SEARCH_STATE);
      return;
    }

    const data = apiData ?? [];

    const selectableKeywords = getInitialSelectableKeywords(data);

    setShareOfSearchCountryMonthlyState((prev) => {
      return {
        ...prev,
        data: mapAndFilterSosByLocationData(data, selectableKeywords),
        selectableKeywords,
        isError,
        isLoading,
      };
    });
  }, [apiData, enabled, isError, isLoading]);

  const toggleKeywords = (keywordsToBeToggled: Array<SelectableKeyword>) => {
    const allPassedKeywordsToggled = keywordsToBeToggled.reduce(
      (prev, curr) => {
        return prev && curr.selected;
      },
      true,
    );

    const toggledKeywordsSet = new Set(
      keywordsToBeToggled.map((k) => k.keyword),
    );

    const existingSelectedKeywords =
      shareOfSearchCountryMonthlyState.selectableKeywords;

    const selectableKeywords = existingSelectedKeywords
      .map((selectableKeyword) => {
        if (toggledKeywordsSet.has(selectableKeyword.keyword)) {
          return {
            ...selectableKeyword,
            selected: !allPassedKeywordsToggled,
          };
        }
        return selectableKeyword;
      })
      .sort(sortKeywordData);

    setShareOfSearchCountryMonthlyState((prevState) => ({
      ...prevState,
      data: mapAndFilterSosByLocationData(apiData ?? [], selectableKeywords),
      selectableKeywords,
    }));
  };

  return {
    shareOfSearchCountryMonthlyState,
    toggleKeywords,
  };
};
