import { useMemo } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { INIT_MM_DATA_STATE, MMDataState } from '../types';
import { aggregateRoi } from '../utils/aggregateRoi';

export const useRoi = (
  enabled: boolean,
  investments: MMDataState,
  decomps: MMDataState,
) => {
  const { currentCustomer } = useAppContext();

  return useMemo(() => {
    if (!enabled || !currentCustomer) {
      return { roi: INIT_MM_DATA_STATE };
    }

    const roi = aggregateRoi(investments, decomps, currentCustomer);

    return { roi };
  }, [enabled, investments, decomps, currentCustomer]);
};
