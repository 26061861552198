import { useEffect, useState } from 'react';
import { ApiInvestmentDataPoint, useInvestmentsQuery } from 'src/api/queries';
import { useFilterContext } from 'src/contexts/FilterContext';
import { toApiDates } from 'src/utils/date';

export type InvestmentsData = {
  data: ApiInvestmentDataPoint[];
  totalInvestment: number;
  totalInvestmentByChannel: Record<string, number>;
  isLoading: boolean;
  isError: boolean;
};

const INIT_INVESTMENT_DATA: Readonly<InvestmentsData> = {
  data: [],
  totalInvestment: 0,
  totalInvestmentByChannel: {},
  isLoading: false,
  isError: false,
};

// Supposed to only be called from context
export const useFetchInvestments = (enabled: boolean) => {
  const { currentModelVersion, timeRange, filteredCampaignIds, media } =
    useFilterContext();

  const [investments, setInvestments] =
    useState<InvestmentsData>(INIT_INVESTMENT_DATA);

  const investmentQueryBase = {
    customer: currentModelVersion?.customer ?? 'missing_customer',
    model_build: currentModelVersion?.buildID ?? 'missing_buildid',
  };

  const {
    data: apiData,
    isLoading,
    isError,
  } = useInvestmentsQuery(
    {
      ...investmentQueryBase,
      ...toApiDates(timeRange),
    },
    enabled,
  );

  // Normal data
  useEffect(() => {
    if (!enabled) {
      setInvestments(INIT_INVESTMENT_DATA);
      return;
    }
    const data = apiData ?? [];
    let totalInvestment = 0;
    const investmentByChannel: Record<string, number> = {};

    for (const investmentApiDatapoint of data) {
      for (const channelInvestment of investmentApiDatapoint.investments) {
        const investment = channelInvestment.value;
        const channel = channelInvestment.channel;

        totalInvestment += investment;
        if (!investmentByChannel[channel]) {
          investmentByChannel[channel] = 0;
        }
        investmentByChannel[channel] += investment;
      }
    }

    setInvestments({
      data,
      totalInvestment,
      totalInvestmentByChannel: investmentByChannel,
      isLoading,
      isError,
    });
  }, [apiData, isLoading, isError, media, filteredCampaignIds, enabled]);

  return { investments };
};
