import { useEffect, useState } from 'react';
import {
  ApiDecompDataPoint,
  ModelRelatedQueryBaseParams,
  useDecompsQuery,
} from 'src/api/queries';
import { useFilterContext } from 'src/contexts/FilterContext';
import { toApiDates } from 'src/utils/date';
import { filterDecompsData } from 'src/utils/filters';

export type ModelledConversionsDataState = {
  data: Array<ApiDecompDataPoint>;
  isLoading: boolean;
  isError: boolean;
};

const INIT_CONVERSIONS_DATA: Readonly<ModelledConversionsDataState> = {
  data: [],
  isError: false,
  isLoading: false,
};

// Supposed to only be called from context
export const useFetchModelledConversions = (enabled: boolean) => {
  const {
    currentModelVersion,
    timeRange,
    filteredCampaignIds,
    media,
    campaignsMap,
    benchmarkTimerange,
  } = useFilterContext();

  const [modelledConversions, setModelledConversions] =
    useState<ModelledConversionsDataState>(INIT_CONVERSIONS_DATA);

  const [benchmarkModelledConversions, setBenchmarkModelledConversions] =
    useState<ModelledConversionsDataState>(INIT_CONVERSIONS_DATA);

  const modelledConversionsQueryBase: ModelRelatedQueryBaseParams = {
    customer: currentModelVersion?.customer ?? 'missing_customer',
    model_build: currentModelVersion?.buildID ?? 'missing_buildid',
    model_id: currentModelVersion?.modelID ?? 'missing_modelid',
  };

  const {
    data: apiData,
    isLoading,
    isError,
  } = useDecompsQuery(
    {
      ...modelledConversionsQueryBase,
      ...toApiDates(timeRange),
    },
    enabled,
  );

  // Benchmark timerange conversions data
  const benchmarkQueryEnabled =
    enabled && benchmarkTimerange.enabled && !benchmarkTimerange.error;
  const {
    data: benchmarkData,
    isLoading: isBenchmarkLoading,
    isError: isBenchmarkError,
  } = useDecompsQuery(
    {
      ...modelledConversionsQueryBase,
      ...toApiDates(benchmarkTimerange),
    },
    benchmarkQueryEnabled,
  );

  // Normal data
  useEffect(() => {
    if (!enabled) {
      setModelledConversions(INIT_CONVERSIONS_DATA);
      return;
    }

    const filteredData = filterDecompsData(
      apiData ?? [],
      filteredCampaignIds,
      media,
    );

    setModelledConversions({
      isError,
      isLoading,
      data: filteredData,
    });
  }, [
    enabled,
    apiData,
    isError,
    isLoading,
    filteredCampaignIds,
    media,
    campaignsMap,
  ]);

  // Benchmark data
  useEffect(() => {
    if (!benchmarkQueryEnabled) {
      setBenchmarkModelledConversions(INIT_CONVERSIONS_DATA);
      return;
    }

    const filteredBenchmarkData = filterDecompsData(
      benchmarkData ?? [],
      filteredCampaignIds,
      media,
    );

    setBenchmarkModelledConversions({
      isError: isBenchmarkError,
      isLoading: isBenchmarkLoading,
      data: filteredBenchmarkData,
    });
  }, [
    isBenchmarkLoading,
    isBenchmarkError,
    benchmarkData,
    benchmarkQueryEnabled,
    campaignsMap,
    filteredCampaignIds,
    media,
  ]);

  return {
    modelledConversions,
    benchmarkModelledConversions,
  };
};
