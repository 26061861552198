import { ShareOfSearchByRegionPast12MonthsDataPoint } from '../types';
import { isKeywordCustomerBrand } from './isKeywordCustomerBrand';

export type SoSPast12MonthsByRegion = {
  regionName: string;
  customerShareOfSearch: number;
  customerShareOfSearchString: string;
  allShareOfSearch: Array<{ shareOfSearch: number; keyword: string }>;
};

export const mapPast12MonthRegionDataToSosByRegion = (
  data: Array<ShareOfSearchByRegionPast12MonthsDataPoint>,
  currentCustomer: string,
): Record<string, SoSPast12MonthsByRegion> => {
  const results: Array<SoSPast12MonthsByRegion> = data.map(
    ({ keywords, location, regionTotal }) => {
      let customerShareOfSearch = 0;

      const allShareOfSearch = keywords
        .map(({ keyword, searchVolume }) => {
          const isCustomerKeyword = isKeywordCustomerBrand(
            keyword,
            currentCustomer,
          );

          const shareOfSearch =
            regionTotal > 0 ? (100 * searchVolume) / regionTotal : 0;

          if (isCustomerKeyword) {
            customerShareOfSearch += shareOfSearch;
          }

          return { shareOfSearch, keyword };
        })
        .sort((a, b) => b.shareOfSearch - a.shareOfSearch);

      return {
        regionName: location.locationName,
        customerShareOfSearch,
        customerShareOfSearchString: `${customerShareOfSearch.toFixed(0)}%`,
        allShareOfSearch,
      };
    },
  );

  const record: Record<string, SoSPast12MonthsByRegion> = {};

  results.forEach((res) => {
    record[res.regionName] = res;
  });

  return record;
};
