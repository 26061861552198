import { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { useAnalystCustomersQuery } from 'src/api/analyst';
import { sortStrings } from 'src/utils/sort';
import { AppContext, CustomersList, SubscriptionLevels } from '.';
import { useDataCustomerQuery } from '../../api/queries';
import { UserRole, useAuthContext } from '../AuthContext';
import { selectClientEvent } from 'src/libs/amp';

const DEFAULT_SUBSCRIPTION_LEVELS: Readonly<SubscriptionLevels> = {
  mm: false,
  da: false,
  mo: false,
  lt: false,
  sos: false,
  isLoading: false,
  isError: false,
};

export function AppContextProvider(props: PropsWithChildren) {
  const [currentCustomer, setCurrentCustomer] = useState<string | null>(null);
  const [customersList, setCustomersList] = useState<CustomersList>({
    customers: [],
    isLoading: false,
    isError: false,
  });
  const [subscriptionLevels, setSubscriptionLevels] =
    useState<SubscriptionLevels>(DEFAULT_SUBSCRIPTION_LEVELS);

  const { userProfile } = useAuthContext();
  // Check the current users role if they are an admin or analyst -
  // These users are granted escalated privileges in the app.
  // This privilege is basically more options in nav/menus -
  // any enforcement of authorization si handeld by the API.
  const isSuperuser =
    userProfile?.role === UserRole.ADMIN ||
    userProfile?.role === UserRole.ANALYST;

  // Get the customer(s) for the user. If the user is an admin or analyst, fetch a list of all customers.
  // If the user is a regular user, the customer(s) are based on their group membership in the ID token.
  const {
    data: customersListData,
    isLoading: customersListDataLoading,
    isError: customersListDataError,
  } = useAnalystCustomersQuery(isSuperuser);

  useEffect(() => {
    if (!isSuperuser) {
      return;
    }

    const customersData = customersListData
      ? customersListData.map((c) => c.customer)
      : [];

    setCustomersList({
      customers: sortStrings(customersData),
      isLoading: customersListDataLoading,
      isError: customersListDataError,
    });
  }, [
    isSuperuser,
    customersListData,
    customersListDataLoading,
    customersListDataError,
  ]);

  // If the query is not enabled because a user is not and ADMIN or ANALYST, get the available groups from the Auth token
  useEffect(() => {
    if (isSuperuser) {
      return;
    }

    const userAuthenticated = !!userProfile?.email;
    setCustomersList({
      customers: sortStrings(userProfile?.customers ?? []),
      isLoading: !userAuthenticated,
      isError: false,
    });
  }, [isSuperuser, userProfile]);

  useEffect(() => {
    if (currentCustomer) {
      selectClientEvent(currentCustomer);
    }
  }, [currentCustomer]);

  useEffect(() => {
    if (!currentCustomer && customersList?.customers?.length > 0) {
      setCurrentCustomer(customersList.customers[0]);
    }
  }, [currentCustomer, customersList]);

  // Check which features of the app are enabled for the user. Analysts or admins have access to all features,
  // Users have access to features in line with their subscription level.
  // Note that this only controls access to items in the nav/menus - authorization to access any app functions
  // or data is enforce by the API.

  const {
    data: appData,
    isLoading: appDataLoading,
    isError: appDataError,
  } = useDataCustomerQuery(
    { customer: currentCustomer ?? 'missing_customer' },
    !isSuperuser && !!currentCustomer,
  );

  useEffect(() => {
    // Check if any data is returned & that the query is enabled to run
    if (isSuperuser) {
      setSubscriptionLevels({
        mm: true,
        da: true,
        mo: true,
        lt: true,
        sos: true,
        isLoading: false,
        isError: false,
      });
      return;
    }

    setSubscriptionLevels({
      mm: !!appData?.mm,
      da: !!appData?.da,
      mo: !!appData?.mo,
      lt: !!appData?.lt,
      sos: !!appData?.sos,
      isLoading: appDataLoading,
      isError: appDataError,
    });
  }, [isSuperuser, currentCustomer, appData, appDataLoading, appDataError]);

  const value = useMemo(() => {
    return {
      currentCustomer,
      setCurrentCustomer,
      customersList,
      subscriptionLevels,
      isSuperuser,
    };
  }, [currentCustomer, customersList, subscriptionLevels, isSuperuser]);

  return (
    <AppContext.Provider value={value}>{props.children}</AppContext.Provider>
  );
}
