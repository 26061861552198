import { useEffect, useState } from 'react';
import { useMediaNamesQuery } from 'src/api/queries';
import { MediaNamesMap } from 'src/types';
import { useAppContext } from '../AppContext';

type MediaNamesState = {
  mediaNames: MediaNamesMap;
  isLoading: boolean;
  isError: boolean;
};

export const useMediaNames = () => {
  const { currentCustomer } = useAppContext();
  const [mediaNamesState, setMediaNamesState] = useState<MediaNamesState>({
    mediaNames: {},
    isLoading: false,
    isError: false,
  });

  const { data, isError, isLoading } = useMediaNamesQuery(
    {
      customer: currentCustomer ?? 'no_customer',
    },
    currentCustomer !== null,
  );

  useEffect(() => {
    const mediaNamesMap: MediaNamesMap = {};
    data?.forEach(({ mediaName, humanReadable }) => {
      mediaNamesMap[mediaName] = humanReadable;
    });

    setMediaNamesState({
      mediaNames: mediaNamesMap,
      isError,
      isLoading,
    });
  }, [data, isError, isLoading]);

  return { mediaNamesMap: mediaNamesState.mediaNames };
};
