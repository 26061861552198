import { useEffect, useState } from 'react';
import { ApiLongTermEffectsDataPoint } from 'src/api/queries';
import { useAppContext } from 'src/contexts/AppContext';
import { ROIState } from 'src/features/LT/types';
import { sortEpoch } from 'src/utils/date';
import { getYearlyAndMonthlyLongTermEffectsData } from '../utils/longTermEffects';
import { getRoiTimeFrameData } from '../utils/roi';

const INIT_ROI_STATE: Readonly<ROIState> = {
  monthly: [],
  yearly: [],
};

export function useRoi(
  data: Array<ApiLongTermEffectsDataPoint>,
  enabled: boolean,
) {
  const { currentCustomer } = useAppContext();

  const [roi, setRoi] = useState<ROIState>(INIT_ROI_STATE);

  useEffect(() => {
    if (!enabled) {
      setRoi(INIT_ROI_STATE);
      return;
    }

    const timeBased = getYearlyAndMonthlyLongTermEffectsData(data);
    const monthly = getRoiTimeFrameData(
      timeBased.monthly,
      currentCustomer,
    ).sort(sortEpoch);
    const yearly = getRoiTimeFrameData(timeBased.yearly, currentCustomer).sort(
      sortEpoch,
    );

    setRoi({ monthly, yearly });
  }, [data, currentCustomer, enabled]);

  return roi;
}
