import { ApiShareOfSearchByLocationDataPoint } from '../api/queries';
import { isKeywordCustomerBrand } from './isKeywordCustomerBrand';

export const splitSearchVolmeToBrandAndOthers = (
  currentCustomer: string,
  data: ApiShareOfSearchByLocationDataPoint[],
) => {
  const brandData: Record<number, number> = {};
  const otherData: Record<number, number> = {};

  data.forEach(({ keywords, utcEpoch }) => {
    if (!brandData[utcEpoch]) {
      brandData[utcEpoch] = 0;
    }
    if (!otherData[utcEpoch]) {
      otherData[utcEpoch] = 0;
    }

    keywords.forEach(({ keyword, searchVolume }) => {
      const isBrand = isKeywordCustomerBrand(keyword, currentCustomer);
      if (isBrand) {
        brandData[utcEpoch] += searchVolume;
      } else {
        otherData[utcEpoch] += searchVolume;
      }
    });
  });

  return { brandData, otherData };
};
