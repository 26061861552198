import { useEffect, useState } from 'react';
import { ApiInvestmentDataPoint, useInvestmentsQuery } from 'src/api/queries';
import { useFilterContext } from 'src/contexts/FilterContext';
import { sortEpoch, toApiDates } from 'src/utils/date';
import { filterInvestmentsData } from 'src/utils/filters';
import { InvestmentDatapoint } from '../../types';

export type InvestmentsDataState = {
  daily: InvestmentDatapoint[];
  isLoading: boolean;
  isError: boolean;
};

const INIT_INVESTMENT_DATA: Readonly<InvestmentsDataState> = {
  daily: [],
  isLoading: false,
  isError: false,
};

const injectTotalInvestment = (
  filteredData: ApiInvestmentDataPoint[],
): Array<InvestmentDatapoint> => {
  return filteredData.map((i) => {
    const totalInvestment = i.investments.reduce(
      (prev, curr) => prev + curr.value,
      0,
    );
    return {
      ...i,
      totalInvestment,
    };
  });
};

// Supposed to only be called from context
export const useFetchInvestments = (enabled: boolean) => {
  const {
    currentModelVersion,
    timeRange,
    filteredCampaignIds,
    media,
    benchmarkTimerange,
  } = useFilterContext();

  const [investments, setInvestments] =
    useState<InvestmentsDataState>(INIT_INVESTMENT_DATA);

  const [benchmarkInvestments, setBenchmarkInvestments] =
    useState<InvestmentsDataState>(INIT_INVESTMENT_DATA);

  const investmentQueryBase = {
    customer: currentModelVersion?.customer ?? 'missing_customer',
    model_build: currentModelVersion?.buildID ?? 'missing_buildid',
  };

  const {
    data: apiData,
    isLoading,
    isError,
  } = useInvestmentsQuery(
    {
      ...investmentQueryBase,
      ...toApiDates(timeRange),
    },
    enabled,
  );

  // Benchmark timerange investments data
  const benchmarkQueryEnabled =
    enabled && benchmarkTimerange.enabled && !benchmarkTimerange.error;
  const {
    data: benchmarkData,
    isLoading: isBenchmarkLoading,
    isError: isBenchmarkError,
  } = useInvestmentsQuery(
    {
      ...investmentQueryBase,
      ...toApiDates(benchmarkTimerange),
    },
    benchmarkQueryEnabled,
  );

  // Normal data
  useEffect(() => {
    if (!enabled) {
      setInvestments(INIT_INVESTMENT_DATA);
      return;
    }

    const filteredData = filterInvestmentsData(
      apiData ?? [],
      filteredCampaignIds,
      media,
    );

    const sorted = injectTotalInvestment(filteredData).sort(sortEpoch);
    setInvestments({
      daily: sorted,
      isLoading,
      isError,
    });
  }, [apiData, isLoading, isError, media, filteredCampaignIds, enabled]);

  // Benchmark data
  useEffect(() => {
    if (!benchmarkQueryEnabled) {
      setBenchmarkInvestments(INIT_INVESTMENT_DATA);
      return;
    }

    const filteredData = filterInvestmentsData(
      benchmarkData ?? [],
      filteredCampaignIds,
      media,
    );

    const sorted = injectTotalInvestment(filteredData).sort(sortEpoch);
    setBenchmarkInvestments({
      daily: sorted,
      isLoading: isBenchmarkLoading,
      isError: isBenchmarkError,
    });
  }, [
    benchmarkData,
    isBenchmarkLoading,
    isBenchmarkError,
    media,
    filteredCampaignIds,
    benchmarkQueryEnabled,
  ]);

  return { investments, benchmarkInvestments };
};
