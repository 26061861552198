import { useCallback, useEffect, useState } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { sortByLocationName } from 'src/utils/sort';
import { useShareOfSearchAvailableRegionsAndCitiesForCountryQuery } from '../api/queries';
import { SelectableLocation, SosLocation } from '../types';
import { toggleLocations } from '../utils/toggleSelectableLocations';

const getSelectableLocations = (
  data: Array<SosLocation>,
): Array<SelectableLocation> => {
  const selectableLocations = data.map(
    (location): SelectableLocation => ({ ...location, selected: true }),
  );

  return sortByLocationName(selectableLocations);
};

const filterSelectableCities = (
  allCitiesData: Array<SosLocation>,
  selectableCities: Array<SelectableLocation>,
  regions: Array<SelectableLocation>,
): Array<SelectableLocation> => {
  const selectedRegionsSet = new Set(
    regions
      .filter(({ selected }) => selected)
      .map((region) => region.locationId),
  );

  const selectedCitiesSet = new Set(
    selectableCities
      .filter(({ selected }) => selected)
      .map((city) => city.locationId),
  );

  const currentlySelectableCities = new Set(
    selectableCities.map((city) => city.locationId),
  );

  const cities = allCitiesData
    .filter((location) => {
      const parentId = location.locationParentId;

      if (!parentId) {
        return false;
      }

      return selectedRegionsSet.has(parentId);
    })
    .map((location): SelectableLocation => {
      const cityId = location.locationId;

      // If the currently selectable cities does not have the city id, it means
      // that after changes to regions it is now available and thus we auto select it.
      // Otherwise, we look at if it was previously selected or not
      const selected =
        !currentlySelectableCities.has(cityId) || selectedCitiesSet.has(cityId);
      return {
        ...location,
        selected,
      };
    });

  return sortByLocationName(cities);
};

export type AvailableRegionsAndCitiesState = {
  regions: Array<SelectableLocation>;
  allCities: Array<SosLocation>;
  selectableCities: Array<SelectableLocation>;
  isLoading: boolean;
  isError: boolean;
};

const INIT_STATE: Readonly<AvailableRegionsAndCitiesState> = {
  regions: [],
  allCities: [],
  selectableCities: [],
  isError: false,
  isLoading: false,
};

export const useAvailableRegionsAndCitiesForCountry = (
  enabled: boolean,
  selectedCountry: SosLocation,
) => {
  const { currentCustomer } = useAppContext();

  const [availableRegionsAndCitiesState, setAvailableRegionsAndCitiesState] =
    useState<AvailableRegionsAndCitiesState>(INIT_STATE);

  const {
    data: apiData,
    isError,
    isLoading,
  } = useShareOfSearchAvailableRegionsAndCitiesForCountryQuery(
    {
      customer: currentCustomer ?? 'no_customer',
      country_id: selectedCountry.locationId,
      country_code: selectedCountry.countryCode,
    },
    enabled,
  );

  useEffect(() => {
    if (!enabled) {
      setAvailableRegionsAndCitiesState(INIT_STATE);
      return;
    }

    const regions = getSelectableLocations(apiData?.regions ?? []);
    const allCities = getSelectableLocations(apiData?.cities ?? []);
    const selectableCities = getSelectableLocations(apiData?.cities ?? []);

    setAvailableRegionsAndCitiesState({
      allCities,
      selectableCities,
      regions,
      isError,
      isLoading,
    });
  }, [apiData, enabled, isError, isLoading]);

  const toggleRegions = useCallback(
    (regionsToBeToggled: Array<SelectableLocation>) => {
      const regions = toggleLocations(
        regionsToBeToggled,
        availableRegionsAndCitiesState.regions,
      );

      setAvailableRegionsAndCitiesState((prevState) => ({
        isError: prevState.isError,
        isLoading: prevState.isLoading,
        allCities: prevState.allCities,
        // Changing regions effects what cities are selectable
        selectableCities: filterSelectableCities(
          prevState.allCities,
          prevState.selectableCities,
          regions,
        ),
        regions,
      }));
    },
    [availableRegionsAndCitiesState.regions],
  );

  const toggleCities = useCallback(
    (citiesToBeToggled: Array<SelectableLocation>) => {
      const selectableCities = toggleLocations(
        citiesToBeToggled,
        availableRegionsAndCitiesState.selectableCities,
      );

      // Toggling cities does not effect regions data
      setAvailableRegionsAndCitiesState((prevState) => ({
        allCities: prevState.allCities,
        selectableCities,
        isLoading: prevState.isLoading,
        isError: prevState.isError,
        regions: prevState.regions,
      }));
    },
    [availableRegionsAndCitiesState.selectableCities],
  );

  return {
    availableRegionsAndCitiesState,
    toggleCities,
    toggleRegions,
  };
};
