import { useEffect, useState } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { captureMessage } from 'src/libs/sentry';
import { sortByLocationName } from 'src/utils/sort';
import { useShareOfSearchAvailableCountriesQuery } from '../api/queries';
import { SosLocation } from '../types';
import { FINLAND } from '../utils/hardCodedLocations';

export type AvailableCountriesState = {
  data: Array<SosLocation>;
  isLoading: boolean;
  isError: boolean;
};

const INIT_AVAILABLE_COUNTRIES_STATE: Readonly<AvailableCountriesState> = {
  data: [],
  isError: false,
  isLoading: false,
};

export const useAvailableCountries = (enabled: boolean) => {
  const { currentCustomer } = useAppContext();

  const [selectedCountry, setSelectedCountry] = useState<SosLocation>(FINLAND);

  const [availableCountriesState, setAvailableCountriesState] =
    useState<AvailableCountriesState>(INIT_AVAILABLE_COUNTRIES_STATE);

  const {
    data: apiData,
    isError,
    isLoading,
  } = useShareOfSearchAvailableCountriesQuery(
    {
      customer: currentCustomer ?? 'no_customer',
    },
    enabled,
  );

  useEffect(() => {
    if (!enabled) {
      setAvailableCountriesState(INIT_AVAILABLE_COUNTRIES_STATE);
      setSelectedCountry(FINLAND);
      return;
    }

    if (
      !!apiData &&
      !apiData.some((d) => d.locationId === FINLAND.locationId)
    ) {
      captureMessage({
        message: `Available countries did not include our init default country ${FINLAND.locationName}`,
        fn: 'useShareOfSearchAvailableCountries',
      });
    }

    const data = sortByLocationName(apiData ?? []);
    setSelectedCountry(FINLAND);

    setAvailableCountriesState({ data, isError, isLoading });
  }, [apiData, enabled, isError, isLoading]);

  return {
    selectedCountry,
    availableCountriesState,
    setSelectedCountry,
  };
};
