import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { AppServices } from 'src/app/routes/utils';
import request from './request';

export type KpiUnit = 'euro' | 'conversion' | 'unit';
export type ModelFrequency = 'daily' | 'weekly' | 'monthly';

export type CustomerDataPoint = {
  customer: string;
  da: boolean;
  lt: boolean;
  mm: boolean;
  mo: boolean;
  sos: boolean;
};

export type ApiProductionCostsDatapoint = {
  utcEpoch: number;
  productionCosts: Array<{
    campaignId: string;
    productionCost: number;
  }>;
};

export type ApiModelVersionDataPoint = {
  alias: string;
  buildID: string;
  created: string;
  keep: boolean;
  modelID: string;
  modelVersion: string;
  updated: string;
  kpiUnit: KpiUnit;
  modelFrequency: ModelFrequency;
  dataStartDate: string;
  dataStartEpoch: number;
  dataEndDate: string;
  dataEndEpoch: number;
  masterDataAvailable: boolean;
  modelType: string;
};

export type ApiSalesDatapoint = {
  sales: number;
  utcEpoch: number;
};

export type ApiMarginDatapoint = {
  margin: number;
  utcEpoch: number;
};

export type ApiABCResponseCurveDatapoint = {
  channel: string;
  meanSpend: number;
  meanResponse: number;
  abcA: number;
  abcB: number;
  abcC: number;
};

export type ApiInvestmentDataPoint = {
  utcEpoch: number;
  investments: Array<{
    campaignId: string;
    channel: string;
    deviceId?: string;
    messageId?: string;
    value: number;
  }>;
};

export type ApiObservedConversionDataPoint = {
  utcEpoch: number;
  conversions: Array<{
    campaignId: string;
    channel: string;
    deviceId?: string;
    messageId?: string;
    clicks?: number;
    calculatedReach?: number;
    conversions?: number;
    impressions?: number;
  }>;
};

export type ApiTagDataPoint = {
  tagGroup: string;
  groupName: string;
  tags: Array<string>;
};

export type ApiDecompDataPoint = {
  utcEpoch: number;
  createdValue: Array<{
    channel: string;
    campaignId: string;
    deviceId?: string;
    messageId?: string;
    metric: 'reach' | 'spend_as_reach';
    value: number;
  }>;
};

export type ApiTotalDecompDataPoint = {
  utcEpoch: number;

  decompTotal: number;
  base: number;
  mediaEffect: number;
  decompMetrics: Array<{
    metric: string;
    value: number;
  }>;
};

export type ApiCampaignDataPoint = {
  endDate: string;
  startDate: string;
  id: string;
  name: string;
  tags: Array<{ tagGroup: string; tag: string }>;
};

export type ApiLongTermEffectsDataPoint = {
  utcEpoch: number;
  base: number;
  shareOfSearch: number;
  mediaLongTermEffects: {
    media: string;
    longTermEffect: number;
    shortTermEffect: number;
    spend: number;
  }[];
};

export type ApiOrganicSearchDataPoint = {
  utcEpoch: number;
  keywordClusters: {
    keywordCluster: string;
    impressions: number;
    modelledConversions: number;
  }[];
};

export type ApiMediaNamesDataPoint = {
  mediaName: string;
  humanReadable: string;
};

export type ModelRelatedQueryBaseParams = {
  customer: string;
  model_build: string;
  model_id: string;
};

export type TimerangeQueryParams = {
  from: string;
  to: string;
};

export type ModelAndTimerangeQueryParams = ModelRelatedQueryBaseParams &
  TimerangeQueryParams;

// TODO These need fetched from the API specification,
// All types are contained there.

export type DataCustomerQueryParam = { customer: string };
export type DataCustomerResponse = { data: CustomerDataPoint };

export type DecompsQueryParam = ModelAndTimerangeQueryParams;
export type DecompsResponse = { data: Array<ApiDecompDataPoint> };
export type TotalDecompsQueryParam = ModelAndTimerangeQueryParams;
export type TotalDecompsResponse = { data: Array<ApiTotalDecompDataPoint> };
export type ModelVersionsQueryParam = {
  customer: string;
  model_type: AppServices;
};
export type ModelVersionsResponse = { data: Array<ApiModelVersionDataPoint> };
export type MediaInvestmentQueryParam = {
  customer: string;
  model_build: string;
  from: string;
  to: string;
};
export type MediaInvestmentResponse = {
  data: Array<ApiInvestmentDataPoint>;
};
export type MediaObservedConversionsQueryParam = {
  customer: string;
  model_build: string;
  from: string;
  to: string;
};

export type MediaObservedConversionsResponse = {
  data: Array<ApiObservedConversionDataPoint>;
  supportedCalculatedReachMedia: Array<string>;
};
export type CampaignsQueryParam = {
  customer: string;
  model_build: string;
  from: string;
  to: string;
};
export type CampaignsQueryResponse = { data: Array<ApiCampaignDataPoint> };
export type CampaignTagsQueryParam = {
  customer: string;
  model_build: string;
};
export type CampaignTagsQueryResponse = { data: Array<ApiTagDataPoint> };
export type MediaQueryParam = {
  customer: string;
  model_build: string;
};
export type MediaQueryResponse = { data: Array<string> };
export type SalesQueryParam = {
  customer: string;
  from: string;
  to: string;
  model_build: string;
};
export type SalesQueryResponse = {
  data: Array<ApiSalesDatapoint>;
};

export type ProductionCostsQueryParam = {
  customer: string;
  from: string;
  to: string;
  model_build: string;
};
export type ProductionCostsQueryResponse = {
  data: Array<ApiProductionCostsDatapoint>;
};

export type PredictedSalesQueryParam = {
  customer: string;
  from: string;
  to: string;
  model_build: string;
  model_id: string;
};
export type PredictedSalesQueryResponse = {
  data: Array<ApiSalesDatapoint>;
};
export type MarginsQueryParam = {
  customer: string;
  from: string;
  to: string;
  model_build: string;
};

export type MarginsQueryResponse = {
  data: Array<ApiMarginDatapoint>;
};
export type ABCResponseCurvesQueryParam = {
  customer: string;
  model_build: string;
  model_id: string;
};
export type ABCResponseCurvesQueryResponse = {
  data: Array<ApiABCResponseCurveDatapoint>;
};

export type AggregationsQueryParam = {
  customer: string;
  model_build: string;
  model_id: string;
};

export type LongTermEffectsQueryParam = {
  customer: string;
  model_build: string;
  model_id: string;
};
export type LongTermEffectsResponse = {
  data: Array<ApiLongTermEffectsDataPoint>;
};

export type OrganicSearchQueryParam = {
  customer: string;
  from: string;
  to: string;
  model_build: string;
  model_id: string;
};
export type OrganicSearchResponse = {
  data: Array<ApiOrganicSearchDataPoint>;
};

export type MediaNamesQueryParam = {
  customer: string;
};
export type MediaNamesResponse = {
  data: Array<ApiMediaNamesDataPoint>;
};

export type OptimizationsQueryParam = {
  customer: string;
  model_build: string;
  model_id: string;
};

export type OptimizationsResponse = {
  data: Array<ApiOptimizationDataPoint>;
};

export type OptimizationConstraint = {
  type: 'FIXED' | 'MAX' | 'MIN';
  value: number;
  media: string;
  period: {
    from: string;
    to: string;
  };
};

export type ApiOptimizationDataPoint = {
  optimizationId: string;
  optimizationName: string;
  targetInvestment: number;
  period: {
    from: string;
    to: string;
  };
  constraints: Array<OptimizationConstraint>;
  data: Array<{
    channel: string;
    initialEffect: number;
    optimizedEffect: number;
    initialInvestment: number;
    optimizedInvestment: number;
  }>;
};

// This controls how often the queries are retried.
// https://react-query.tanstack.com/guides/query-retries
// For the moment, if there are failures then the problem
// won't be resolved by a few retries so just skip them.
export const QUERY_RETRY = false;

// These control how long it is before query data is considered stale (in milliseconds)
// Some calls related to app context data should have shorter query periods,
// whereas anything data related can have a longer period (e.g. 12 hours).

const APP_QUERY_EXPIRATION = 60 * 60 * 1000;
export const DATA_QUERY_EXPIRATION = 12 * 60 * 60 * 1000;

export function useDataCustomerQuery(
  params: DataCustomerQueryParam,
  enabled: boolean,
) {
  const queryId = useMemo(() => {
    return ['useDataCustomerQuery', params.customer];
  }, [params.customer]);
  return useQuery(
    queryId,
    () => {
      return request
        .get<DataCustomerResponse>('/data/customer', { params })
        .then((response) => {
          return response.data?.data || {};
        });
    },
    { retry: QUERY_RETRY, staleTime: APP_QUERY_EXPIRATION, enabled },
  );
}

export function useModelVersionsQuery(
  params: ModelVersionsQueryParam,
  enabled: boolean,
) {
  // The query key is used to cache the results
  const queryId = useMemo(() => {
    return ['useModelVersionsQuery', params.customer, params?.model_type];
  }, [params.customer, params?.model_type]);
  return useQuery(
    queryId,
    () => {
      return request
        .get<ModelVersionsResponse>('/data/modelversions', { params })
        .then((response) => {
          return response.data?.data || [];
        });
    },
    { retry: QUERY_RETRY, staleTime: DATA_QUERY_EXPIRATION, enabled },
  );
}

export function useCampaignsQuery(
  params: CampaignsQueryParam,
  enabled: boolean,
) {
  // The query key is used to cache the results
  const queryId = useMemo(() => {
    return [
      'useCampaignsQuery',
      params.customer,
      params.model_build,
      params.from,
      params.to,
    ];
  }, [params.customer, params.model_build, params.from, params.to]);
  return useQuery(
    queryId,
    () => {
      return request
        .get<CampaignsQueryResponse>('/data/campaigns', { params })
        .then((response) => {
          return response.data?.data || [];
        });
    },
    { retry: QUERY_RETRY, staleTime: DATA_QUERY_EXPIRATION, enabled },
  );
}

export function useCampaignTagsQuery(
  params: CampaignTagsQueryParam,
  enabled: boolean,
) {
  // The query key is used to cache the results
  const queryId = useMemo(() => {
    return ['useCampaignTagsQuery', params.customer, params.model_build];
  }, [params.customer, params.model_build]);
  return useQuery(
    queryId,
    () => {
      return request
        .get<CampaignTagsQueryResponse>('/data/campaigntags', { params })
        .then((response) => {
          return response.data?.data || [];
        });
    },
    { retry: QUERY_RETRY, staleTime: DATA_QUERY_EXPIRATION, enabled },
  );
}

export function useMediaQuery(params: MediaQueryParam, enabled: boolean) {
  // The query key is used to cache the results
  const queryId = useMemo(() => {
    return ['useMediaQuery', params.customer, params.model_build];
  }, [params.customer, params.model_build]);
  return useQuery(
    queryId,
    () => {
      return request
        .get<MediaQueryResponse>('/data/channels', { params })
        .then((response) => {
          return response.data?.data || [];
        });
    },
    { retry: QUERY_RETRY, staleTime: DATA_QUERY_EXPIRATION, enabled },
  );
}

export function useSalesQuery(params: SalesQueryParam, enabled: boolean) {
  // The query key is used to cache the results
  const queryId = useMemo(() => {
    return [
      'useSalesQuery',
      params.from,
      params.to,
      params.customer,
      params.model_build,
    ];
  }, [params.from, params.to, params.customer, params.model_build]);
  return useQuery(
    queryId,
    () => {
      return request
        .get<SalesQueryResponse>('/data/sales', { params })
        .then((response) => {
          return response.data?.data || [];
        });
    },
    { retry: QUERY_RETRY, staleTime: DATA_QUERY_EXPIRATION, enabled },
  );
}

export function useProductionCostsQuery(
  params: ProductionCostsQueryParam,
  enabled: boolean,
) {
  // The query key is used to cache the results
  const queryId = useMemo(() => {
    return [
      'useProductionCostsQuery',
      params.from,
      params.to,
      params.customer,
      params.model_build,
    ];
  }, [params.from, params.to, params.customer, params.model_build]);
  return useQuery(
    queryId,
    () => {
      return request
        .get<ProductionCostsQueryResponse>('/data/productioncost', { params })
        .then((response) => {
          return response.data?.data || [];
        });
    },
    { retry: QUERY_RETRY, staleTime: DATA_QUERY_EXPIRATION, enabled },
  );
}

export function useMarginsQuery(params: MarginsQueryParam, enabled: boolean) {
  // The query key is used to cache the results
  const queryId = useMemo(() => {
    return [
      'useMarginQuery',
      params.from,
      params.to,
      params.customer,
      params.model_build,
    ];
  }, [params.from, params.to, params.customer, params.model_build]);
  return useQuery(
    queryId,
    () => {
      return request
        .get<MarginsQueryResponse>('/data/margins', { params })
        .then((response) => {
          return response.data?.data || [];
        });
    },
    { retry: QUERY_RETRY, staleTime: DATA_QUERY_EXPIRATION, enabled },
  );
}

export function usePredictedSalesQuery(
  params: PredictedSalesQueryParam,
  enabled: boolean,
) {
  // The query key is used to cache the results
  const queryId = useMemo(() => {
    return [
      'usePredictedSalesQuery',
      params.from,
      params.to,
      params.customer,
      params.model_build,
      params.model_id,
    ];
  }, [
    params.from,
    params.to,
    params.customer,
    params.model_build,
    params.model_id,
  ]);
  return useQuery(
    queryId,
    () => {
      return request
        .get<PredictedSalesQueryResponse>('/data/predictedsales', { params })
        .then((response) => {
          return response.data?.data || [];
        });
    },
    { retry: QUERY_RETRY, staleTime: DATA_QUERY_EXPIRATION, enabled },
  );
}

export function useABCResponseCurvesQuery(
  params: ABCResponseCurvesQueryParam,
  enabled: boolean,
) {
  // The query key is used to cache the results
  const queryId = useMemo(() => {
    return [
      'useABCResponseCurvesQuery',
      params.customer,
      params.model_build,
      params.model_id,
    ];
  }, [params.customer, params.model_build, params.model_id]);
  return useQuery(
    queryId,
    () => {
      return request
        .get<ABCResponseCurvesQueryResponse>('/data/abcresponsecurves', {
          params,
        })
        .then((response) => {
          return response.data?.data || [];
        });
    },
    { retry: QUERY_RETRY, staleTime: DATA_QUERY_EXPIRATION, enabled },
  );
}

export function useInvestmentsQuery(
  params: MediaInvestmentQueryParam,
  enabled: boolean,
) {
  const queryId = useMemo(() => {
    return [
      'useInvestmentQuery',
      params.customer,
      params.model_build,
      params.from,
      params.to,
    ];
  }, [params.from, params.to, params.customer, params.model_build]);
  return useQuery(
    queryId,
    () => {
      return request
        .get<MediaInvestmentResponse>('data/investments', { params })
        .then((response) => {
          return response.data?.data || [];
        });
    },
    { retry: QUERY_RETRY, staleTime: DATA_QUERY_EXPIRATION, enabled },
  );
}

export function useObservedConversionsQuery(
  params: MediaObservedConversionsQueryParam,
  enabled: boolean,
) {
  const queryId = useMemo(() => {
    return [
      'useObservedConversionsQuery',
      params.customer,
      params.model_build,
      params.from,
      params.to,
    ];
  }, [params.from, params.to, params.customer, params.model_build]);

  return useQuery(
    queryId,
    () => {
      return request
        .get<MediaObservedConversionsResponse>('data/observed-conversions', {
          params,
        })
        .then((response) => {
          const { data, supportedCalculatedReachMedia } = response.data;

          const mediaSet = new Set(supportedCalculatedReachMedia);
          // Inject the 0 value calculatedReach values if the reach is supported
          return data.map((d) => {
            return {
              ...d,
              conversions: d.conversions.map((conversion) => {
                if (
                  mediaSet.has(conversion.channel) &&
                  !conversion.calculatedReach
                ) {
                  return { ...conversion, calculatedReach: 0 };
                } else {
                  return conversion;
                }
              }),
            };
          });
        });
    },
    { retry: QUERY_RETRY, staleTime: DATA_QUERY_EXPIRATION, enabled },
  );
}

export function useDecompsQuery(params: DecompsQueryParam, enabled: boolean) {
  const queryId = useMemo(() => {
    return [
      'useDecompsQuery',
      params.customer,
      params.model_build,
      params.model_id,
      params.from,
      params.to,
    ];
  }, [
    params.customer,
    params.model_build,
    params.model_id,
    params.from,
    params.to,
  ]);
  return useQuery(
    queryId,
    () => {
      return request
        .get<DecompsResponse>('data/decomps', { params })
        .then((response) => {
          return response.data?.data || [];
        });
    },
    { retry: QUERY_RETRY, staleTime: DATA_QUERY_EXPIRATION, enabled },
  );
}

export function useTotalDecompsQuery(
  params: TotalDecompsQueryParam,
  enabled: boolean,
) {
  const queryId = useMemo(() => {
    return [
      'useTotalDecompsQuery',
      params.customer,
      params.model_build,
      params.model_id,
      params.from,
      params.to,
    ];
  }, [
    params.customer,
    params.model_build,
    params.model_id,
    params.from,
    params.to,
  ]);
  return useQuery(
    queryId,
    () => {
      return request
        .get<TotalDecompsResponse>('data/totaldecomps', { params })
        .then((response) => {
          return response.data?.data || [];
        });
    },
    { retry: QUERY_RETRY, staleTime: DATA_QUERY_EXPIRATION, enabled },
  );
}

export function useLongTermEffectsQuery(
  params: LongTermEffectsQueryParam,
  enabled: boolean,
) {
  const queryId = useMemo(() => {
    return [
      'useLongTermEffectsQuery',
      params.customer,
      params.model_build,
      params.model_id,
    ];
  }, [params.customer, params.model_build, params.model_id]);
  return useQuery(
    queryId,
    () => {
      return request
        .get<LongTermEffectsResponse>('data/longtermeffects', { params })
        .then((response) => {
          return response.data?.data || [];
        });
    },
    { retry: QUERY_RETRY, staleTime: DATA_QUERY_EXPIRATION, enabled },
  );
}

export function useOrganicSearchQuery(
  params: OrganicSearchQueryParam,
  enabled: boolean,
) {
  const queryId = useMemo(() => {
    return [
      'useOrganicSearchQuery',
      params.customer,
      params.model_build,
      params.model_id,
      params.from,
      params.to,
    ];
  }, [
    params.customer,
    params.model_build,
    params.model_id,
    params.from,
    params.to,
  ]);
  return useQuery(
    queryId,
    () => {
      return request
        .get<OrganicSearchResponse>('data/organicsearch', { params })
        .then((response) => {
          return response.data?.data || [];
        });
    },
    { retry: QUERY_RETRY, staleTime: DATA_QUERY_EXPIRATION, enabled },
  );
}

export function useMediaNamesQuery(
  params: MediaNamesQueryParam,
  enabled: boolean,
) {
  const queryId = useMemo(() => {
    return ['useMediaNamesQuery', params.customer];
  }, [params.customer]);
  return useQuery(
    queryId,
    () => {
      return request
        .get<MediaNamesResponse>('data/medianames', { params })
        .then((response) => {
          return response.data?.data || [];
        });
    },
    { retry: QUERY_RETRY, staleTime: DATA_QUERY_EXPIRATION, enabled },
  );
}

export function useOptimizationsQuery(
  params: OptimizationsQueryParam,
  enabled: boolean,
) {
  const queryId = useMemo(() => {
    return [
      'useOptimizationsQuery',
      params.customer,
      params.model_build,
      params.model_id,
    ];
  }, [params.customer, params.model_build, params.model_id]);
  return useQuery(
    queryId,
    () => {
      return request
        .get<OptimizationsResponse>('data/optimizations', { params })
        .then((response) => {
          return response.data?.data || [];
        });
    },
    { retry: QUERY_RETRY, staleTime: DATA_QUERY_EXPIRATION, enabled },
  );
}
