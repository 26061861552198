import { useEffect, useState } from 'react';

import { useAppContext } from 'src/contexts/AppContext';
import {
  ApiShareOfSearchLocationsAggregateDataPoint,
  useShareOfSearchLocationsAggregateQuery,
} from '../api/queries';
import { SelectableLocation } from '../types';
import { mapAndFilterSosByLocationData } from '../utils/mapAndFilterSosByLocationData';
import { SelectableKeyword } from './useShareOfSearchCountryMonthly';

export type ShareOfSearchLocationsAggregateState = {
  data: Array<ApiShareOfSearchLocationsAggregateDataPoint>;
  isLoading: boolean;
  isError: boolean;
};

const INIT_SHARE_OF_SEARCH_STATE: ShareOfSearchLocationsAggregateState = {
  data: [],
  isError: false,
  isLoading: false,
};

export const useShareOfSearchLocationsAggregate = (
  enabled: boolean,
  selectableKeywords: Array<SelectableKeyword>,
  selectableLocations: Array<SelectableLocation>,
) => {
  const { currentCustomer } = useAppContext();

  const [
    shareOfSearchLocationsAggregateState,
    setShareOfSearchLocationsAggregateState,
  ] = useState<ShareOfSearchLocationsAggregateState>(
    INIT_SHARE_OF_SEARCH_STATE,
  );

  const locationIds = selectableLocations
    .filter((l) => l.selected)
    .map((l) => l.locationId);

  const queryEnabled = enabled && locationIds.length > 0;

  const { data, isError, isLoading } = useShareOfSearchLocationsAggregateQuery(
    {
      customer: currentCustomer ?? 'no_customer',
      locationIds,
    },
    queryEnabled,
  );

  useEffect(() => {
    if (!queryEnabled) {
      setShareOfSearchLocationsAggregateState(INIT_SHARE_OF_SEARCH_STATE);
      return;
    }

    setShareOfSearchLocationsAggregateState({
      data: mapAndFilterSosByLocationData(data ?? [], selectableKeywords),
      isError,
      isLoading,
    });
  }, [queryEnabled, data, isError, isLoading, selectableKeywords]);

  return {
    shareOfSearchLocationsAggregateState,
  };
};
