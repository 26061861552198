import { Box, Grid, GridItem, Text } from '@chakra-ui/react';
import { Fragment } from 'react';
import { TooltipContentContainer } from 'src/components/atoms/tooltips/TooltipContentContainer';
import { isKeywordCustomerBrand } from 'src/features/SOS/utils/isKeywordCustomerBrand';
import { SoSPast12MonthsByRegion } from 'src/features/SOS/utils/mapPast12MonthRegionDataToSos';
import { benchmarkColor } from 'src/theme';

export type HoverInfo = {
  regionData: SoSPast12MonthsByRegion;
  x: number;
  y: number;
};

type RegionMapTooltipProps = {
  hoverInfo: HoverInfo;
  currentCustomer: string;
};

export const RegionMapTooltip: React.FC<RegionMapTooltipProps> = ({
  hoverInfo,
  currentCustomer,
}) => {
  const regionData = hoverInfo.regionData;

  return (
    <Box
      h={0}
      zIndex={100}
      position="relative"
      style={{
        left: Math.round(hoverInfo.x + 20),
        top: Math.round(hoverInfo.y + 5),
      }}>
      <TooltipContentContainer
        position={'relative'}
        flexShrink={0}
        width={'fit-content'}
        display="flex"
        flexDir="column">
        <Box fontWeight="bold" whiteSpace="nowrap">
          {regionData.regionName}
        </Box>
        <Grid templateColumns="min-content min-content">
          {regionData.allShareOfSearch.map(({ keyword, shareOfSearch }) => {
            const isBrand = isKeywordCustomerBrand(keyword, currentCustomer);
            const color = isBrand ? benchmarkColor : '#FFF';
            return (
              <Fragment key={keyword}>
                <GridItem
                  pr="1.5"
                  fontWeight="bold"
                  whiteSpace="nowrap"
                  color={color}>
                  <Text fontSize="12px">{keyword}:</Text>
                </GridItem>
                <GridItem
                  mr="20px"
                  fontWeight="bold"
                  whiteSpace="nowrap"
                  color={color}>
                  <Text fontSize="12px"> {shareOfSearch.toFixed(1)}%</Text>
                </GridItem>
              </Fragment>
            );
          })}
        </Grid>
      </TooltipContentContainer>
    </Box>
  );
};
