import { createContext, useContext } from 'react';
import { AppServices } from 'src/app/routes/utils';
import { Dayjs } from 'src/libs/dayjs';
import {
  BenchmarkTimeRange,
  Campaign,
  CampaignTag,
  CampaignTagGroup,
  CampaignToTags,
  Media,
  TimePeriod,
  TimeRange,
} from 'src/types';
import { ApiModelVersionDataPoint } from '../../api/queries';
import { ModelVersionsState } from './useModelVersions';

export interface FilterContextValue {
  currentAppService: AppServices | null;
  currentModelVersion: (ApiModelVersionDataPoint & { customer: string }) | null;
  changeCurrentModelVersion: (modelVersion: string) => void;
  modelVersionsState: ModelVersionsState;
  campaignTagGroups: Array<CampaignTagGroup>;
  toggleAllCampaignTagGroups: () => void;
  toggleCampaignTagGroup: (tagGroup: string) => void;
  toggleTags: (tagsToBeToggled: Array<CampaignTag>) => void;
  campaigns: Array<Campaign>;
  campaignsMap: Record<string, Campaign>;
  toggleCampaigns: (campaignsToBeToggled: Array<Campaign>) => void;
  filteredCampaignIds: string[];
  media: Media[];
  toggleMediaItem: (media: Media) => void;
  toggleAllMediaItems: () => void;
  timeRange: TimeRange;
  updateTimeRange: (timePeriod: TimePeriod, start?: Dayjs, end?: Dayjs) => void;
  campaignToTags: CampaignToTags;
  setBenchmarkEnabled: (benchmarkEnabled: boolean) => void;
  setBenchmarkTimerange: (start: Dayjs, end: Dayjs) => void;
  benchmarkTimerange: BenchmarkTimeRange;
  campaignsLocked: boolean;
  setCampaignsLocked: (locked: boolean) => void;
  mediaLocked: boolean;
  setMediaLocked: (locked: boolean) => void;
  timeRangeLocked: boolean;
  setTimeRangeLocked: (locked: boolean) => void;
  mediaColorMap: Record<string, string>;
}

export const FilterContext = createContext<FilterContextValue | null>(null);
FilterContext.displayName = 'FilterContext';

export function useFilterContext() {
  const value = useContext(FilterContext);
  if (!value) {
    throw new Error('useFilterContext has to be used under FilterProvider');
  }
  return value;
}
