import { CUSTOMER_VAASAN } from 'src/constants';
import { useAppContext } from 'src/contexts/AppContext';
import { EURO_ROI_DEF, UNIT_ROI_DEF } from './metricDefinitions';

const calculateEuroRoi = (reach: number, investment: number): number => {
  if (investment < 1) {
    return 0;
  }
  return (reach - investment) / investment;
};

const calculateUnitRoi = (reach: number, investment: number): number => {
  if (investment < 1) {
    return 0;
  }

  return reach / investment;
};

type CalculateRoiParams = {
  mediaIncrementalSales: number;
  investment: number;
  customer: string | null;
};

enum RoiFormula {
  euro,
  unit,
}

const getRoiFormula = (customer: string | null): RoiFormula => {
  /// Ugly hack for now

  if (customer === CUSTOMER_VAASAN) {
    return RoiFormula.euro;
  }
  return RoiFormula.unit;
};

export const calculateRoi = ({
  mediaIncrementalSales,
  investment,
  customer,
}: CalculateRoiParams): number => {
  const roiFormula = getRoiFormula(customer);

  if (roiFormula === RoiFormula.euro) {
    return calculateEuroRoi(mediaIncrementalSales, investment);
  }

  return calculateUnitRoi(mediaIncrementalSales, investment);
};

export const useRoiDef = () => {
  const { currentCustomer } = useAppContext();

  const roiFormula = getRoiFormula(currentCustomer);

  if (roiFormula === RoiFormula.euro) {
    return EURO_ROI_DEF;
  }

  return UNIT_ROI_DEF;
};
