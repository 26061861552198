import { Flex, ModalBody } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { CreateOptimizationRequest } from 'src/api/mutations';
import {
  ApiModelVersionDataPoint,
  OptimizationConstraint,
} from 'src/api/queries';
import { FormNumberInput } from 'src/components/molecules/formInput/FormNumberInput';
import { FormTextInput } from 'src/components/molecules/formInput/FormTextInput';
import { FormTimePeriodInput } from 'src/components/molecules/formInput/FormTimePeriodInput';
import { useMODataContext } from 'src/features/MO/contexts/MODataContext';
import { dayjs } from 'src/libs/dayjs';
import { Constraints } from '../Constraints';
import { Footer } from './Footer';

export type ConstraintType = 'FIXED' | 'MAX' | 'MIN';

export type Constraint = {
  type: ConstraintType;
  value: number | null;
  media: string;
  period: {
    from: Date;
    to: Date;
    entirePeriod: boolean;
  };
};

export type OptimizationFormInputs = {
  name: string;
  investment: number;
  periodStart: Date;
  periodEnd: Date;
  constraints: Array<Constraint>;
};

type OptimizationModalContentProps = {
  onClose: () => void;
  currentModel: ApiModelVersionDataPoint & { customer: string };
};

export const OptimizationModalContent: React.FC<
  OptimizationModalContentProps
> = ({ onClose, currentModel }) => {
  const { createOptimization, investments } = useMODataContext();

  const initPeriodData = {
    periodStart: dayjs(currentModel.dataStartDate).toDate(),
    periodEnd: dayjs(currentModel.dataEndDate).toDate(),
  };

  const methods = useForm<OptimizationFormInputs>({
    defaultValues: {
      name: '',
      investment: Math.round(investments.totalInvestment),
      constraints: [],
      ...initPeriodData,
    },
  });

  const onSubmit = async (values: OptimizationFormInputs) => {
    const optimizationPeriod = {
      from: dayjs(values.periodStart).format('YYYY-MM-DD'),
      to: dayjs(values.periodEnd).format('YYYY-MM-DD'),
    };

    const constraints: Array<OptimizationConstraint> = values.constraints
      .map((c) => {
        const isEntirePeriod = c.period.entirePeriod;
        const period = {
          from: dayjs(c.period.from).format('YYYY-MM-DD'),
          to: dayjs(c.period.to).format('YYYY-MM-DD'),
        };
        return {
          ...c,
          period: isEntirePeriod ? optimizationPeriod : period,
        };
      })
      .filter((c): c is OptimizationConstraint => {
        return c.value !== null;
      });

    const createOptimizationBody: CreateOptimizationRequest = {
      customer: currentModel.customer,
      optimizationName: values.name,
      targetInvestment: values.investment,
      constraints,
      period: optimizationPeriod,
      modelBuild: currentModel.buildID,
      modelId: currentModel.modelID,
    };
    try {
      await createOptimization(createOptimizationBody);
      onClose();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      // eslint-disable-next-line no-alert
      window.alert('Creation of optimization failed');
    }
  };

  return (
    <ModalBody>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Flex flexDir={'row'}>
            <FormTextInput<OptimizationFormInputs>
              inputPath="name"
              placeholder="Name"
              helperText="Used to help identify your optimization"
              label="Optimization name"
              required
              sx={{ maxW: '400px' }}
            />
            <FormTimePeriodInput<OptimizationFormInputs>
              periodFromInputPath="periodStart"
              periodToInputPath="periodEnd"
              helperText={`Selected date range for the optimization. Model period: ${dayjs(
                initPeriodData.periodStart,
              ).format('YYYY-MM-DD')} - ${dayjs(
                currentModel.dataEndDate,
              ).format('YYYY-MM-DD')}`}
              label="Period"
              sx={{ mx: '80px' }}
            />
            <FormNumberInput<OptimizationFormInputs>
              helperText={`Total target investment for time period. Model investment: ${investments.totalInvestment.toFixed(
                0,
              )} €`}
              inputPath="investment"
              required
              suffix="€"
              label="Investment"
              placeholder="Investment"
              sx={{ maxW: '400px' }}
            />
          </Flex>
          <Constraints />
          <Footer onCancelClick={onClose} />
        </form>
      </FormProvider>
    </ModalBody>
  );
};
