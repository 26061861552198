import { useEffect, useState } from 'react';
import { useProductionCostsQuery } from 'src/api/queries';
import { useFilterContext } from 'src/contexts/FilterContext';
import { toApiDates } from 'src/utils/date';
import { filterProductionCostData } from 'src/utils/filters';
import { INIT_MM_DATA_STATE, MMDataState } from '../../types';
import { aggregateApiDataToDataState } from '../../utils/aggregate';

// Supposed to only be called from context
export const useFetchProductionCosts = (enabled: boolean) => {
  const {
    currentModelVersion,
    timeRange,
    campaignToTags,
    filteredCampaignIds,
  } = useFilterContext();

  const [productionCosts, setProductionCosts] =
    useState<MMDataState>(INIT_MM_DATA_STATE);

  const {
    data: apiData,
    isLoading,
    isError,
  } = useProductionCostsQuery(
    {
      customer: currentModelVersion?.customer ?? 'missing_customer',
      model_build: currentModelVersion?.buildID ?? 'missing_buildid',
      ...toApiDates(timeRange),
    },
    enabled,
  );

  useEffect(() => {
    if (!enabled) {
      setProductionCosts(INIT_MM_DATA_STATE);
      return;
    }

    const data = apiData ?? [];

    const filteredData = filterProductionCostData(data, filteredCampaignIds);

    const newState = aggregateApiDataToDataState(
      'productionCosts',
      'productionCost',
      filteredData,
      isLoading,
      isError,
      campaignToTags,
    );

    setProductionCosts(newState);
  }, [
    apiData,
    isLoading,
    isError,
    enabled,
    campaignToTags,
    filteredCampaignIds,
  ]);

  return { productionCosts };
};
