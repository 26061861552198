import { DataBoxLegendProps } from 'src/components/molecules/DataBox';
import { cleanCustomerName } from 'src/utils/cleanCustomerName';

export const OTHERS_SEARCH_VOLUME = 'OTHERS_SEARCH_VOLUME';
export const CUSTOMER_CHANGE = 'CUSTOMER_CHANGE';
export const OTHERS_CHANGE = 'OTHERS_CHANGE';

export const getLegendProps = (
  currentCustomer: string | null,
): DataBoxLegendProps => {
  return {
    placement: 'right',
    baseLegendKeys: [CUSTOMER_CHANGE, OTHERS_CHANGE],
    lineKeys: [OTHERS_SEARCH_VOLUME],
    labelMap: {
      [OTHERS_SEARCH_VOLUME]: 'Others search volume, last 12 months',
      [OTHERS_CHANGE]: 'Others, previous month change %',
      [CUSTOMER_CHANGE]: `${
        cleanCustomerName(currentCustomer) ?? ''
      }, previous month change %`,
    },
    keyColorMap: {
      [OTHERS_CHANGE]: 'rgb(184,176,173)',
      [CUSTOMER_CHANGE]: '#EAA16F',
    },
    minWidth: '300px',
  };
};
