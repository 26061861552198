import { PropsWithChildren, useMemo } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { useFilterContext } from 'src/contexts/FilterContext';
import { MODataContext } from '.';
import { useFetchInvestments } from '../../hooks/useFetchInvestments';
import { useModelMarginPct } from '../../hooks/useModelMarginPct';
import { useOptimizations } from '../../hooks/useOptimizations';

export function MODataContextProvider(props: PropsWithChildren) {
  const { subscriptionLevels } = useAppContext();
  const { currentModelVersion } = useFilterContext();

  // Make sure we only run queries if customers have the correct subscriptions enabled
  // and a model version is selected (so that there are no errors & data is available)
  const enabled =
    !subscriptionLevels.isLoading &&
    !subscriptionLevels.isError &&
    !!subscriptionLevels.mo &&
    !!currentModelVersion;

  const marginPct = useModelMarginPct(enabled);

  const { createOptimization, optimizations, deleteOptimization } =
    useOptimizations(enabled, marginPct);
  const { investments } = useFetchInvestments(enabled);

  const value = useMemo(() => {
    return {
      optimizations,
      createOptimization,
      deleteOptimization,
      marginPct,
      investments,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optimizations, marginPct, investments]);

  return (
    <MODataContext.Provider value={value}>
      {props.children}
    </MODataContext.Provider>
  );
}
