export const isKeywordCustomerBrand = (
  keyword: string,
  currentCustomer: string,
) => {
  const customer = currentCustomer
    .toLowerCase()
    .replaceAll('_', ' ')
    .replaceAll('-', ' ');

  const lowercaseKeyword = keyword
    .toLowerCase()
    .replaceAll('_', ' ')
    .replaceAll('-', ' ');

  return lowercaseKeyword.includes(customer);
};
