import { useQuery } from 'react-query';
import request from './request';
import { CustomerDataPoint } from './queries';

export type AnalystCustomersResponse = {
  data: Array<CustomerDataPoint>;
};

const ANALYST_QUERY_EXPIRATION = 30 * 60 * 1000;

const QUERY_RETRY = false;

export function useAnalystCustomersQuery(enabled: boolean = true) {
  const queryId = 'useAnalystCustomersQuery';
  return useQuery(
    queryId,
    () => {
      return request
        .get<AnalystCustomersResponse>('/analyst/customers')
        .then((response) => {
          return response.data?.data || [];
        });
    },
    { retry: QUERY_RETRY, staleTime: ANALYST_QUERY_EXPIRATION, enabled },
  );
}
