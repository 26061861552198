import { Box, Flex, Menu, Spinner, Text } from '@chakra-ui/react';
import React from 'react';
import { Icon } from 'src/components/atoms/Icon';
import { MenuButton } from 'src/components/atoms/MenuButton';
import { MenuOption } from 'src/components/atoms/MenuOption';
import { MenuTriangle } from 'src/components/atoms/MenuTriangle';
import { MenuList } from 'src/components/molecules/MenuList';
import { useAppContext } from 'src/contexts/AppContext';
import { useFontErrorColor } from 'src/hooks/colors/useErrorColor';
import { cleanCustomerName } from 'src/utils/cleanCustomerName';

const MENU_BUTTON_NAME = 'change-client';

/**
 * This component will allow users to change the current customer view IF they
 * have more than one customer available to view. If the user only has the ability to see
 * a single customer, then not option is presented.
 */
export const ChangeClientMenu: React.FC = () => {
  const { currentCustomer, setCurrentCustomer, customersList } =
    useAppContext();

  const fontErrorColor = useFontErrorColor();

  // Only render the selection menu if there is more than one customer available.
  return (
    <Box px={4}>
      {customersList?.isError && (
        <Flex alignItems="center" color={fontErrorColor}>
          <Icon iconName="warning" mr={2} />
          <Text fontSize="16px" fontWeight={700} lineHeight={1}>
            Error fetching customer view
          </Text>
        </Flex>
      )}
      {customersList?.isLoading && (
        <Flex alignItems="center">
          <Spinner size="sm" mr={2} />
          <Text fontSize="16px" fontWeight={700} lineHeight={1}>
            Loading...
          </Text>
        </Flex>
      )}
      {!customersList?.isLoading && !customersList?.isError && (
        <>
          {customersList?.customers?.length > 1 ? (
            <Menu>
              {({ isOpen, onClose }) => (
                <>
                  <MenuButton
                    buttonName={MENU_BUTTON_NAME}
                    color="current"
                    fontWeight={700}>
                    <Flex align="center">
                      <Text textTransform="capitalize">
                        {cleanCustomerName(currentCustomer)}
                      </Text>
                      <MenuTriangle isOpen={isOpen} />
                    </Flex>
                  </MenuButton>
                  <MenuList buttonName={MENU_BUTTON_NAME}>
                    {customersList?.customers?.map((name) => {
                      const label = cleanCustomerName(name);

                      if (!label) {
                        return null;
                      }

                      return (
                        <MenuOption
                          colorSelected
                          onClick={() => {
                            setCurrentCustomer(name);
                            onClose();
                          }}
                          buttonName={MENU_BUTTON_NAME}
                          key={name}
                          value={name}
                          label={label}
                          isSelected={currentCustomer === name}>
                          {label}
                        </MenuOption>
                      );
                    })}
                  </MenuList>
                </>
              )}
            </Menu>
          ) : (
            <Text
              fontSize="16px"
              fontWeight={700}
              lineHeight={1}
              textTransform="capitalize">
              {cleanCustomerName(currentCustomer)}
            </Text>
          )}
        </>
      )}
    </Box>
  );
};
